import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import { useEffect, useState } from "react";
import { submitMeetingStatusById } from "../../../../../Utils/Api";


const ViewDogsInfo = ({ selectedAddress, data, step, updateStep, bookingAt }) => {
  const navigate = useNavigate()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const dogData = JSON.parse(localStorage.getItem("dogList"))

  useEffect(() => {
    dogData.every((res) => {
      res.isVerified && setIsSubmitDisabled(false)
    })
  }, [])

  const handleFinalSubmit = () => {
    submitMeetingStatusById(
      {
        "final_booking_location": bookingAt,
        "finalParentAddressId": selectedAddress
      },data.booking_id
    ).then((res) => {
      if(res.data.response_code == 80){
      localStorage.removeItem('dogList')
      navigate('/dashboard/consent/details/confirmation')
      }
    })

  }
  return (
    <>
        <Box className='dogs-container' sx={{mt: 4}}>
          <Box className='dogs-wrapper'>
            {dogData.map((item) => {
              return (
                <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                {item.isVerified && <VerifiedTwoToneIcon sx={{color: '#05a005', fontSize: '60px', position: 'absolute', top: '0px', right: '0px', zIndex: 1}}/>}
                  <Box className='dog-widget'  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                   onClick={() => {
                    localStorage.setItem("selectedDog", JSON.stringify(item));
                    updateStep(3)
                  }}>
                  <Avatar className='dog-widget-avatar' src={item?.photos?.[0]}/>
                  <Typography fontFamily="Brandon" sx={{ textAlign: "center" }}>
                    {item.name}
                  </Typography>
                  {item.dog_form ? <Typography variant="h6" sx={{fontFamily:"Brandon", color: '#18ae9f', fontWeight: 'bold', textAlign: 'center'}}>Click to check last form</Typography> 
                  : 
                  <Typography variant="h6" sx={{fontFamily:"Brandon", color: '#fe7648', fontWeight: 'bold', textAlign: 'center'}}>Click and fill a form for {item.name}</Typography> }
                </Box>
                </Box>
              );
            })}
          </Box>
            <Grid xs={12} sx={{textAlign: 'center'}}>
              <Button className={!isSubmitDisabled  ? "default-btn" : 'cancel-action-btn'} variant="contained" sx={{ mt: 5, mb: 2, width: "25%" }} disabled={isSubmitDisabled} onClick={handleFinalSubmit}> Submit Care Information</Button>
            </Grid>
            <Typography variant="h6" sx={{fontFamily:"Brandon", color: 'red', fontWeight: 'bold', textAlign: 'center'}}>Note: You cannnot submit without checking and filling the form for each dog so please do that first</Typography>
        </Box>
    </>
  );
};

export default ViewDogsInfo;
