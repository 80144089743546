import React, { useState } from "react";
import { Box, Button, Grid, NativeSelect, Typography } from "@mui/material";
import Masthead from "../../../../../../Components/Masthead";
import "../style.css";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../../../Components/CustomElements";
import MultiUpload from "../../../../../../Components/MutipleUpload";
import { ImageUpload, addDog } from "../../../../../../Utils/Api";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../../../../Components/Snackbar";
import { BackBtn } from "../../../../../../Assets/assets";

const CreateDog = () => {
    const navigate = useNavigate()
    const [images, setImages] = useState([])
    const [imagesLength, setImagesLength] = useState(0)
    const [dogFormData, setDogFormData] = useState({
        name: null,
        breed: null,
        gender: null,
        photos: ["null"],
        dob: null,
        is_neutered: null,
        age_category: null,
        size_category: null,
        is_friendly_with_other_dogs: null,
        is_friendly_with_other_dogs_text: null,
        is_comfortable_around_cat: null,
        is_comfortable_around_cat_text: null,
        chew_or_damage_things: null,
        chew_or_damage_things_text: null,
        can_left_alone: null,
        can_left_how_long: null,
        can_left_how_long_text: null,
        reaction_toward_children: null,
        reaction_toward_stranger: null,
        is_toilet_trained: null,
        is_toilet_trained_text: null,
        is_aggressive: null,
        is_aggressive_text: null,
        is_vaccinated: null,
        is_vaccinated_text: null,
        additional_info: null,
      });
      const {
        name,
        breed,
        gender,
        photos,
        dob,
        is_neutered,
        age_category,
        size_category,
        is_friendly_with_other_dogs,
        is_friendly_with_other_dogs_text,
        is_comfortable_around_cat,
        is_comfortable_around_cat_text,
        chew_or_damage_things,
        chew_or_damage_things_text,
        can_left_alone,
        can_left_how_long,
        can_left_how_long_text,
        reaction_toward_children,
        reaction_toward_stranger,
        is_toilet_trained,
        is_toilet_trained_text,
        is_aggressive,
        is_aggressive_text,
        is_vaccinated,
        is_vaccinated_text,
        additional_info,
      } = dogFormData;
      const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})


      const handleCreateDog = async() => {
        const imgUrls = []
        let formData = new FormData()

        name == null || breed == null || gender == null || dob == null || is_neutered == null || age_category == null || size_category == null || photos.length == 0 || is_friendly_with_other_dogs == null || is_comfortable_around_cat == null || chew_or_damage_things == null || reaction_toward_children == null || reaction_toward_stranger == null || is_toilet_trained == null || is_aggressive == null ||is_vaccinated == null ? 
        setSnackbar({...snackbar, isOpen: true, message: "Oops! Looks like you have missed a question", type: 'error'})
        :
        images.map((res) => {
          formData.append('image', res)
        })

        if(images.length > 0){
          await ImageUpload(formData).then((res) => {
            const data = res.data.data.url
            const list = data.map((url) => {return url})
            imgUrls.push(list)
          });
           await addDog({    
              name: name,
              breed: breed,
              gender: gender,
              photos: imgUrls[0],
              dob: dob,
              is_neutered: is_neutered,
              age_category: age_category,
              size_category: size_category,
              is_friendly_with_other_dogs: is_friendly_with_other_dogs,
              is_friendly_with_other_dogs_text:  is_friendly_with_other_dogs_text,
              is_comfortable_around_cat: is_comfortable_around_cat,
              is_comfortable_around_cat_text:  is_comfortable_around_cat_text,
              chew_or_damage_things: chew_or_damage_things,
              chew_or_damage_things_text:  chew_or_damage_things_text,
              can_left_alone: can_left_alone,
              can_left_how_long:  can_left_how_long,
              can_left_how_long_text: can_left_how_long_text,
              reaction_toward_children: reaction_toward_children,
              reaction_toward_stranger: reaction_toward_stranger,
              is_toilet_trained: is_toilet_trained,
              is_toilet_trained_text: is_toilet_trained_text,
              is_aggressive: is_aggressive,
              is_aggressive_text: is_aggressive_text,
              is_vaccinated: is_vaccinated,
              is_vaccinated_text:  is_vaccinated_text,
              additional_info:  additional_info
            }).then((res) => {
              if(res.data.response_code == 80){
                navigate('/dashboard/dog')
              }
            })
        }else{
          setSnackbar({...snackbar, isOpen: true, message: "Upload atleast one image", type: 'error'})
        }
      }
      return (
        <Box>
          <Masthead />
          <Box className="view-dog-container">
          <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box className="wrap-back-btn" onClick={() => {navigate('/dashboard/dog')}}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
              Add New Dog
            </Typography>
          </Box>

            <Box>
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mb: 1,
                  fontSize: "22px",
                  textDecoration: "underline",
                }}
              >
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Name <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <InputField
                        type="text"
                        size="small"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, name: e.target.value })
                        }
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Breed <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <InputField
                        type="text"
                        size="small"
                        placeholder="Enter "
                        value={breed}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, breed: e.target.value })
                        }
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Gender <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={gender}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, gender: e.target.value })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                        Date of Birth <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputField
                        name="date_of_birth"
                        type="date"
                        id="date_of_birth"
                        size="small"
                        placeholder="Enter DOB"
                        InputProps={{ inputProps: { max: new Date().toISOString().substring(0,10)} }}
                        value={dob}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, dob: e.target.value })
                        }
                      />
                    </FormInput>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Neutered <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_neutered}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_neutered: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        What age category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={age_category}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            age_category: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="young_puppy">
                          Very Young Puppy (less than 1 year old)
                        </option>
                        <option value="puppy">Puppy (less than 2 years old)</option>
                        <option value="adult_dog">
                          Adult dog (over 2 years old)
                        </option>
                        <option value="senior_dog">
                          Senior dog (above 7 years old)
                        </option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        What size category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={size_category}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            size_category: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="small">Small (less than 14 kilos)</option>
                        <option value="medium">
                          Medium (between 14 and 25 kilos)
                        </option>
                        <option value="large">Large (more than 25 kilos)</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mb: 1,
                  mt: 3,
                  fontSize: "22px",
                  textDecoration: "underline",
                }}
              >
                Additional Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog friendly with other dogs? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_friendly_with_other_dogs}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_friendly_with_other_dogs: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">It Depends</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
    
                  {is_friendly_with_other_dogs && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_friendly_with_other_dogs_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_friendly_with_other_dogs_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">
                        Is your dog comfortable around cats? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_comfortable_around_cat}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_comfortable_around_cat: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">Not Sure</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_comfortable_around_cat && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_comfortable_around_cat_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_comfortable_around_cat_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">
                        Does your dog chew or damage things? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={chew_or_damage_things}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            chew_or_damage_things: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {chew_or_damage_things && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={chew_or_damage_things_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              chew_or_damage_things_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Can your dog be left alone at home for some time?
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={can_left_alone}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            can_left_alone: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {can_left_alone  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          For how long?
                        </CustomLabelInput>
                        <NativeSelect
                          disableUnderline
                          defaultValue=""
                          value={can_left_how_long}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              can_left_how_long: e.target.value,
                            })
                          }
                          inputProps={{
                            style: {
                              fontFamily: "Brandon",
                              padding: "8.5px 14px",
                            },
                          }}
                          sx={{
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                          <option selected disabled value="">
                            Select
                          </option>
                          <option value="1-2 hours">1-2 hours</option>
                          <option value="2-4 hours">2-4 hours</option>
                          <option value="4-6 hours"> 4-6 hours</option>
                          <option value="6-8 hours"> 6-8 hours</option>
                          <option value="As long as needed">
                            {" "}
                            As long as needed
                          </option>
                          <option value="Other"> other</option>
                        </NativeSelect>
                      </FormInput>
                    </Grid>
                  )}
                  {can_left_alone && 
                        <Grid item xs={12} md={10}>
                        <FormInput fullWidth variant="standard">
                          <CustomLabelInput
                            shrink="true"
                          >
                            Any additional details?
                          </CustomLabelInput>
                          <InputTeaxtArea
                            size="small"
                            placeholder="Enter Details"
                            minRows={2}
                            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                            value={can_left_how_long_text}
                            onChange={(e) => setDogFormData({...dogFormData, can_left_how_long_text: e.target.value})}
                          />
                        </FormInput>
                      </Grid>
                        }
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="street_name">
                        How does your dog react to children? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputTeaxtArea
                        maxRows={2}
                        value={reaction_toward_children}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            reaction_toward_children: e.target.value,
                          })
                        }
                        size="small"
                        placeholder="Enter Details"
                        sx={{
                          fontFamily: "Brandon",
                          height: "40px",
                          fontSize: "1rem",
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          padding: "8.5px 14px",
                          marginTop: "5px",
                        }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="street_name">
                        How does your dog react to strangers? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputTeaxtArea
                        maxRows={2}
                        size="small"
                        value={reaction_toward_stranger}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            reaction_toward_stranger: e.target.value,
                          })
                        }
                        placeholder="Enter Details"
                        sx={{
                          fontFamily: "Brandon",
                          height: "40px",
                          fontSize: "1rem",
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          padding: "8.5px 14px",
                          marginTop: "5px",
                        }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog completely toilet trained? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_toilet_trained}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_toilet_trained: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_toilet_trained  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_toilet_trained_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_toilet_trained_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog aggressive in any situation? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_aggressive}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_aggressive: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_aggressive  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_aggressive_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_aggressive_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog fully vaccinated? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_vaccinated}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_vaccinated: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_vaccinated  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_vaccinated_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_vaccinated_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Is there anything else you would like us or the dog sitter to
                      know?
                    </CustomLabelInput>
                    <InputTeaxtArea
                      minRows={2}
                      value={additional_info}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      size="small"
                      placeholder="Enter Details"
                      onChange={(e) => setDogFormData({...dogFormData, additional_info: e.target.value})}
                    />
                  </FormInput>
                </Grid>
              </Grid>
            </Box>
            <Typography
              fontFamily="Brandon"
              sx={{
                width: "100%",
                mb: 1,
                mt: 3,
                fontSize: "22px",
                textDecoration: "underline",
              }}
            >
              Uploads
            </Typography>
            <MultiUpload 
            text='Please upload at least one and maximum 3 pictures of your dog. We need these to find you
            the right dog sitter. (accepted formats: JPEG, JPG, PNG and file size: up to 5 MB each)'
            imageAssets={(res) => {
              setImages(res)
              setImagesLength(res.length)
            }}
            limit={3}
            />
          </Box>
          <Box sx={{textAlign: 'center'}}>
          <Button className="default-btn" onClick={handleCreateDog} variant="contained" sx={{ mt: 3, mb: 5, width: "25%"}}>Add Dog</Button>
          </Box>
          <SnackBar
            open={snackbar.isOpen}
            handleClose={() => setSnackbar({...snackbar, isOpen: false})}
            duration={3000}
            msg={snackbar.message}
            type={snackbar.type}
          />
        </Box>
      );
}

export default CreateDog